import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"

class EventSection extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol lg="12">
          {/* <MDBCol md={this.props.section.image ? '9' : '12'} className="pt-5"> */}
          {this.props.section.image && (
            <GatsbyImage
              image={this.props.section.image.childImageSharp.gatsbyImageData}
              className="img-fluid rounded mb-4"
              alt=""
            />
          )}
          {!this.props.section.imageonly && (
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pt-3 pb-2">
              {this.props.section.title}
            </h2>
          )}
          {this.props.section.subtitle != null && (
            <h3 className="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium">
              {this.props.section.subtitle}
            </h3>
          )}
          {this.props.section.description && (
            <div>
              {renderRichText( this.props.section.description, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => {
                      return (children.length === 1 && children[0][1] !== '') ||
                        children.length > 1 ? (
                        <p
                          className="font-w-400 text-medium"
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          {children}
                        </p>
                      ) : null
                    },
                    [INLINES.HYPERLINK]: (node, children) =>
                      node.data.uri.charAt(0) == '/' ? (
                        <Link to={node.data.uri}>{children}</Link>
                      ) : (
                        <a
                          href={node.data.uri}
                          className="effect"
                          target="_blank"
                        >
                          {children}
                        </a>
                      ),
                  },
                }
              )}
            </div>
          )}

          {this.props.section.feature && (
            <div>
              {this.props.section.feature.map((features, index) => {
                return (
                  <>
                    <p className="font-w-600"> {features.title}</p>
                    <p> {features.subtitle}</p>
                    <p> {features.info}</p>
                    <div>
                      {renderRichText( features.description, {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (node, children) => (
                              <p
                                className="font-w-400 text-medium"
                                style={{ whiteSpace: 'pre-wrap' }}
                              >
                                {children}
                              </p>
                            ),
                            [INLINES.HYPERLINK]: (node, children) =>
                            node.data.uri.charAt(0) == '/' ? (
                              <Link to={node.data.uri}>{children}</Link>
                            ) : (
                              <a
                                href={node.data.uri}
                                className="effect"
                                target="_blank"
                              >
                                {children}
                              </a>
                            ),
                          },
                        }
                      )}
                    </div>
                  </>
                )
              })}
            </div>
          )}
        </MDBCol>
      </MDBRow>
    )
  }
}

export default EventSection